<script setup lang="tsx">
import { useRouteQuery } from '@vueuse/router'
import type { SelectOption } from 'naive-ui'
import { getBalance as _getBalance } from '@wagmi/core'
import { useAccount } from '@wagmi/vue'
import { formatEther, formatUnits, hexToNumber, parseUnits, toBytes, toHex } from 'viem'
import VIcon from '@/components/v/Icon.vue'
import { getWagmiConfig } from '~/lib/wagmi'

const emit = defineEmits(['submit'])

const { modal, closeModal } = useModal()

const route = useRoute()
const { checkChain, currentVaultAddress } = useConnector()
const { address } = useUser()
const { vesselKey } = useKey()
const { currencyList } = useSymbol()
const { chain } = useAccount()
const router = useRouter()
const assetId = useRouteQuery('assetId')
const symbol = useRouteQuery('symbol')

const notification = useNotification()

const registeredAddress = useStorage('REGISTERED_ADDRESS', [])
const current = ref()
const balance = ref('-')
const approveBalance = ref('')
const amount = ref('')
const errorMsg = ref('')
const isLoadingBalance = ref(false)
const isDeposit = ref(false)

const currentItem = computed(() => currencyList.value.find(i => i.assetId === current.value))
const isRegistered = computed(() => registeredAddress.value.includes(address.value))

function toMaxAmount() {
  if (balance.value !== '-') {
    if (current.value === 0) {
      amount.value = `${Math.max(0, +balance.value - 0.01)}`
      const b = `${+balance.value - 0.01}`
      const limit = currentItem.value.depositLimit
      const precision = currentItem.value.depositPrecision
      amount.value = `${b.split('.')[0].slice(-1 * limit)}${b.includes('.') ? '.' : ''}${!b.includes('.') ? '' : b.split('.')?.[1]?.slice(0, precision)}`
    }
    else {
      const limit = currentItem.value.depositLimit
      const precision = currentItem.value.depositPrecision
      amount.value = `${balance.value.split('.')[0].slice(-1 * limit)}${balance.value.includes('.') ? '.' : ''}${!balance.value.includes('.') ? '' : balance.value.split('.')?.[1]?.slice(0, precision)}`
    }
  }
}

async function getBalance(currency: number) {
  const current = currencyList.value.find(i => i.assetId === currency)
  if (!current) {
    balance.value = '-'
    return
  }
  isLoadingBalance.value = true
  if (currency === 0) {
    return _getBalance(getWagmiConfig(), {
      address: address.value,
    }).then((res) => {
      balance.value = formatEther(res.value)
    }).finally(() => {
      isLoadingBalance.value = false
    })
  }
  else {
    return getBalanceFromChain(current.address as `0x${string}`).then((res: any) => {
      balance.value = formatUnits(res, current.onChainDecimal)
    }).finally(() => {
      isLoadingBalance.value = false
    })
  }
}
async function getApproveBalance(currency: number) {
  if (currency === 0) {
    approveBalance.value = balance.value
  }
  else {
    const current = currencyList.value.find(i => i.assetId === currency)
    getAllowanceFromChain(current.address as `0x${string}`).then((res: any) => {
      approveBalance.value = formatUnits(res, current.onChainDecimal)
    })
  }
}

async function handleChangeCurrency(currency: number) {
  amount.value = ''
  await getBalance(currency)
  getApproveBalance(currency)
}

async function getAdminSignature() {
  const res = await vesselApi.user.getAdminSignature()
  const sig = res.data.signature
  return {
    operator: res.data.operator,
    r: sig.slice(0, 66),
    s: `0x${sig.slice(66, 130)}`,
    v: hexToNumber(`0x${sig.slice(130, 132)}`),
  }
}

async function notifySuccess(asset: string) {
  notification.success({
    title: 'Deposit Successful',
    content: `Your deposit of ${asset} has been successfully processed.`,
    duration: 2000,
  })
  await getBalance(current.value)
  getApproveBalance(current.value)
}

async function notifyError(asset: string) {
  notification.success({
    title: 'Deposit Error',
    content: `Your deposit of ${asset} has been failed.`,
    duration: 2000,
  })
  await getBalance(current.value)
  getApproveBalance(current.value)
}

async function notifyWaiting(asset: string) {
  notification.success({
    title: 'Deposit Committed',
    content: `Please wait for the blockchain confirmation for your deposit of ${asset}.`,
    duration: 2000,
  })
  await getBalance(current.value)
  getApproveBalance(current.value)
  isDeposit.value = false
  amount.value = ''
  closeModal('deposit')
}

async function deposit() {
  await checkChain()
  if (isDeposit.value || +amount.value > +balance.value || !+amount.value) {
    return
  }
  let reg: RegExp
  if (currentItem.value.depositPrecision) {
    reg = new RegExp(`^\\d{1,${currentItem.value.depositLimit}}(\\.\\d{1,${currentItem.value.depositPrecision}})?$`)
  }
  else {
    reg = new RegExp(`^\\d{1,${currentItem.value.depositLimit}}$`)
  }
  if (!reg.test(amount.value)) {
    errorMsg.value = `Deposit amount must be < ${10 ** currentItem.value.depositLimit} with < ${currentItem.value.depositPrecision} decimals.`
    return
  }
  try {
    const _amount = amount.value
    isDeposit.value = true
    const depositAmount = parseUnits(amount.value, currentItem.value.onChainDecimal)
    const innerPublicKey = vesselKey.value.publicKey
    // const innerPublicKey = vesselKey.value.publicKey.slice(2)

    if (current.value !== 0) {
      // not enough

      if (+approveBalance.value < +_amount) {
        await approveAmount(currentItem.value.address as `0x${string}`, currentItem.value.onChainDecimal, amount.value)
        await getApproveBalance(current.value)
      }
      if (!isRegistered.value) {
        const { v, r, s, operator } = await getAdminSignature()
        await writeVault('registerAndDepositERC20', [
          innerPublicKey,
          operator,
          v,
          r,
          s,
          currentItem.value.assetId,
          depositAmount,
        ]).then(() => {
          notifyWaiting(`${_amount} ${currentItem.value.assetName}`)
          registeredAddress.value.push(address.value)
        })
      }
      else {
        await writeVault('depositERC20', [
          currentItem.value.assetId,
          depositAmount,
          innerPublicKey,
        ]).then(() => {
          notifyWaiting(`${_amount} ${currentItem.value.assetName}`)
        })
      }
    }
    else {
      // ETH
      if (!isRegistered.value) {
        const { v, r, s, operator } = await getAdminSignature()
        await writeVault('registerAndDepositNative', [
          innerPublicKey,
          operator,
          v,
          r,
          s,
        ], depositAmount).then(() => {
          notifyWaiting(`${_amount} ${currentItem.value.assetName}`)
          registeredAddress.value.push(address.value)
        })
      }
      else {
        await writeVault('depositNative', [
          innerPublicKey,
        ], depositAmount).then(() => {
          notifyWaiting(`${_amount} ${currentItem.value.assetName}`)
        })
      }
    }
  }
  catch (e: any) {
    console.log(e)
    isDeposit.value = false
  }
}

function toFaucet() {
  router.push(`/faucet?token=${currentItem.value.address}`)
  closeModal('deposit')
}

whenever(() => modal.value.deposit, () => {
  checkChain()
  if (assetId.value) {
    current.value = +assetId.value
  }
  else if (symbol.value) {
    const pairs = getCurrencyFromSymbol(symbol.value as string)
    current.value = +pairs.baseId
  }
  else if (!current.value) {
    current.value = currencyList.value.filter(i => i.canDepositFrontend)[0]?.assetId
  }
  handleChangeCurrency(current.value)
})

function renderLabel(option: SelectOption) {
  return (
    <div class="flex items-center justify-start">
      <VIcon currency={option.assetName as string} class="mr-0.08 h-0.24 w-0.24" />
      {option.assetName}
    </div>
  )
}
</script>

<template>
  <v-modal
    v-model:show="modal.deposit"
    modal-class="w-4.48"
    title="Deposit"
  >
    <div class="mb-0.12 text-0.16 text-grey1">
      Select Token
    </div>
    <n-select
      v-model:value="current"
      :render-label="renderLabel"
      :options="currencyList.filter(i => i.canDepositFrontend)"
      filterable
      label-field="assetName" value-field="assetId"
      @update-value="handleChangeCurrency"
    />
    <div class="mb-0.12 mt-0.32 text-0.16 text-grey1">
      Amount
    </div>
    <v-input v-model="amount" align="left" :error-message="errorMsg" @input="errorMsg = ''">
      <template #suffix>
        <v-button type="outline" size="small" @click="toMaxAmount">
          MAX
        </v-button>
      </template>
    </v-input>
    <div class="mt-0.12 flex justify-end gap-x-0.04 text-0.12 font-600 text-grey1">
      <div>Available</div>
      <div class="flex gap-x-0.04 text-white2">
        <svg-loading v-if="isLoadingBalance" class="animate-spin animate-duration-2000" />
        <span v-else>{{ formatInt(balance) }}</span> {{ currentItem?.assetName }}
      </div>
    </div>
    <div v-if="!IS_MAINNET" class="mt-0.32 text-center text-0.12 font-600 text-grey1">
      Remember to visit our
      <span class="cursor-pointer text-primary" @click="toFaucet">Faucet page</span>
      daily to claim your test tokens and supercharge your trial trades, risk-free.
    </div>
    <template #footer>
      <div class="mt-0.32 text-center text-0.12 font-600 text-grey1">
        Your deposit is queued for processing on blockchain and should be completed within approximately 5 minutes.
      </div>
      <v-button
        class="mt-0.32 w-full"
        :loading="isDeposit"
        :disabled="+amount > +balance || !amount" @click="deposit"
      >
        {{ +amount > +approveBalance ? 'Enable' : 'Deposit' }}
      </v-button>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
