import {
  useAccount,
  useChainId,
  useConfig,
  useConnect,
  useDisconnect,
  useReconnect,
  useSwitchChain,
} from '@wagmi/vue'
import { watchAccount } from '@wagmi/core'

export type WalletConnectType = 'metaMaskSDK' | 'walletConnect' | 'okxwallet' | 'rabby'

export const useConnector = createGlobalState(() => {
  const { openModal } = useModal()
  const config = useConfig()
  const { initKey } = useKey()
  const currentChainId = ref(config.chains[0].id)
  const account = useAccount()
  const { reconnect } = useReconnect()
  const { connectAsync, connectors, isSuccess: isConnected } = useConnect()
  const { disconnectAsync: disconnectProvider } = useDisconnect()
  const lastConnectWalletType = useStorage<WalletConnectType | ''>('walletType', '')

  const currentConnector = computed(() => connectors.find(i => i.id === lastConnectWalletType.value))
  const currentVaultAddress = computed(() => {
    const chainConfig = getChainConfigSync()
    return chainConfig.find(i => i.id === currentChainId.value)?.vaultAddress || ''
  })

  const connectWalletFn = useDebounceFn(async (data) => {
    initNewWallet(data.address)
  }, 1000)

  const unwatch = watchAccount(config, {
    async onChange(data) {
      connectWalletFn(data)
    },
  })

  async function connectWallet(walletId: WalletConnectType) {
    lastConnectWalletType.value = walletId
    const connector = connectors.find(i => i.id === walletId)
    await checkChain()
    return connectAsync({
      connector,
      chainId: currentChainId.value,
    }).then(() => {
      checkAndBindVesselKey()
    })
  }

  async function checkChain() {
    if (currentConnector.value && await currentConnector.value.getChainId() !== currentChainId.value) {
      openModal('changeChainID')
      throw new Error('Chain ID is not correct.')
    }
  }

  async function disconnect() {
    lastConnectWalletType.value = ''
    disconnectProvider({ connector: currentConnector.value }).then(() => {
      config.setState(x => ({
        ...x,
        connections: new Map([...config.state.connections].filter(([key, value]) => key !== config.state.current)),
        current: null,
      }))
    })
  }

  function initWallet() {
    reconnect({ connectors: [currentConnector.value] })
  }

  return {
    connectWallet,
    lastConnectWalletType,
    disconnect,
    checkChain,
    isConnected,
    currentConnector,
    currentChainId,
    initWallet,
    currentVaultAddress,
  }
})
